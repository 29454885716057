import React from "react";

const Paper = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="25px"
      height="25px"
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M498.791 161.127c-17.545-17.546-46.094-17.545-63.645.004l-45.128 45.166V87.426c0-12.02-4.681-23.32-13.181-31.819L334.412 13.18C325.913 4.68 314.612 0 302.592 0H45.018c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45V333.631L498.79 224.767c17.587-17.586 17.591-46.052.001-63.64zM300.019 30c2.834 0 8.295-.491 13.18 4.393l42.426 42.427c4.76 4.761 4.394 9.978 4.394 13.18h-60V30zm59.999 437c0 8.271-6.728 15-15 15h-300c-8.271 0-15-6.729-15-15V45c0-8.271 6.729-15 15-15h225v75c0 8.284 6.716 15 15 15h75v116.323l-44.256 44.293-21.203 21.204a15 15 0 0 0-3.624 5.863l-21.214 63.64a14.999 14.999 0 0 0 18.974 18.974l63.64-21.213a14.988 14.988 0 0 0 5.863-3.624l1.82-1.82V467zm-33.64-154.573 21.213 21.213-8.103 8.103-31.819 10.606 10.606-31.82 8.103-8.102zm42.422-.005-21.213-21.213 72.105-72.166 21.213 21.213-72.105 72.166zm108.773-108.864-15.463 15.476-21.213-21.213 15.468-15.481c5.852-5.849 15.366-5.848 21.214 0 5.847 5.85 5.878 15.333-.006 21.218z"
          fill="#707070"
          opacity="1"
          data-original="#707070"
        ></path>
        <path
          d="M285.018 150h-210c-8.284 0-15 6.716-15 15s6.716 15 15 15h210c8.284 0 15-6.716 15-15s-6.716-15-15-15zM225.018 210h-150c-8.284 0-15 6.716-15 15s6.716 15 15 15h150c8.284 0 15-6.716 15-15s-6.716-15-15-15zM225.018 270h-150c-8.284 0-15 6.716-15 15s6.716 15 15 15h150c8.284 0 15-6.716 15-15s-6.716-15-15-15zM225.018 330h-150c-8.284 0-15 6.716-15 15s6.716 15 15 15h150c8.284 0 15-6.716 15-15s-6.716-15-15-15zM285.018 422h-90c-8.284 0-15 6.716-15 15s6.716 15 15 15h90c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
          fill="#707070"
          opacity="1"
          data-original="#707070"
        ></path>
      </g>
    </svg>
  );
};

export default Paper;
