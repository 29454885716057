import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Table, Loader } from "../../../Components";
import {
  deleteNumerical,
  getChapterNumericals,
} from "../../../services";
import { toast } from "react-toastify";
import { Question, Store } from "../../../types";
import useStore from "../../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { isChapter, isClass, isSubject } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const LIMIT = 10;
export const Numericals = () => {
  const { state } = useLocation();
  const { admin } = useStore((store: Store) => store);
  const [page, setPage] = React.useState<number>(1);

  const [loading, setLoading] = React.useState<string>("");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  React.useEffect(() => {
    getChapterNumericals(state?.chapterId, page, LIMIT)
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  }, [state?.chapterId, page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === page) return;
    setIsLoading(true);
    setPage(value);
  };

  const editHandler = (data: Question) => {
    navigate("/numericalform", {
      state: {
        questionData: { ...data },
        classId: state?.classId,
        subjectId: state?.subjectId,
        chapterId: state?.chapterId,
      },
    });
  };

  const deleteHandler = async (id: string | undefined) => {
    if (id) {
      const c = window.confirm("Are you sure to delete?");
      if (!c) return;
      try {
        setLoading(id);
        await deleteNumerical(id, admin ? admin : "");
        let dataCopy = [...data];
        dataCopy = dataCopy.filter((it: any) => it._id !== id);
        setData(dataCopy);
        setLoading("");
      } catch (error: any) {
        setLoading("");
        toast.error(error.message);
      }
    }
  };

  const columns = [
    {
      label: "Question",
      key: "Question",
      content: (item: Question) => (
        <p>
          {item.question.length > 20
            ? item.question.slice(0, 20) + "..."
            : item.question}
        </p>
      ),
    },
    {
      label: "Serial Number",
      path: "serialNumber",
    },
    {
      label: "Language",
      path: "language",
    },
    {
      label: "Class",
      key: "Class",
      content: (item: Question) => (
        <Typography variant="body2">
          {isClass(item.classId) && item.classId.name}
        </Typography>
      ),
    },
    {
      label: "Subject",
      key: "Subject",
      content: (item: Question) => (
        <Typography variant="body2">
          {isSubject(item.subjectId) && item.subjectId.name}
        </Typography>
      ),
    },
    {
      label: "Chapter",
      key: "Chapter",
      content: (item: Question) => (
        <Typography variant="body2">
          {isChapter(item.chapterId) && item.chapterId.name}
        </Typography>
      ),
    },
    {
      label: "",
      key: "View",
      content: (item: Question) => (
        <Button variant="outlined">
          <Link to="/viewquestion" state={item}>
            View
          </Link>
        </Button>
      ),
    },
    {
      label: "",
      key: "Edit",
      content: (item: Question) => (
        <Button onClick={() => editHandler(item)} variant="outlined">
          Edit
        </Button>
      ),
    },
    {
      label: "",
      key: "Delete",
      content: (item: Question) => (
        <LoadingButton
          size="small"
          onClick={() => deleteHandler(item._id)}
          loading={loading !== "" && item._id?.includes(loading)}
          variant="contained"
          color="error"
        >
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];
  if (isLoading) return <Loader />;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Numericals</Typography>
        <Button
          variant="outlined"
          onClick={() =>
            navigate("/numericalform", {
              state: {
                classId: state?.classId,
                subjectId: state?.subjectId,
                chapterId: state?.chapterId,
              },
            })
          }
        >
          Add
        </Button>
      </Box>
      {data?.length ? (
        <Table columns={columns} data={data} />
      ) : (
        <Box my={5} fontSize={17}>
          There is no data.
        </Box>
      )}
      <Box display="flex" justifyContent="start">
        <Pagination onChange={handleChange} count={totalPages} page={page} />
      </Box>
    </Box>
  );
};
