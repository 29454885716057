import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Table, Modal, Loader } from "../../Components";
import { SubjectForm } from "./form";
import {
  deleteSubject,
  getClassSubjects,
  getLimitedClassSubjects,
} from "../../services";
import { toast } from "react-toastify";
import { Subject, Store } from "../../types";
import useStore from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { downloadExcel } from "../../utils";

const LIMIT = 10;
export const Subjects = () => {
  const { state } = useLocation();
  const { admin } = useStore((store: Store) => store);
  const [page, setPage] = React.useState<number>(1);
  const [item, setItem] = React.useState<Subject | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    getLimitedClassSubjects(state?.classId, page, LIMIT)
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  }, [state?.classId, page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === page) return;
    setIsLoading(true);
    setPage(value);
  };

  const editHandler = (data: Subject) => {
    setItem({
      _id: data._id,
      name: data.name,
      icon: data.icon,
      tagLine: data.tagLine,
      language: data.language,
      classId: data.classId,
    });
    setOpen(true);
  };

  const deleteHandler = async (id: string | undefined) => {
    if (id) {
      const c = window.confirm("Are you sure to delete?");
      if (!c) return;
      try {
        setLoading(id);
        await deleteSubject(id, admin ? admin : "");
        let dataCopy = [...data];
        dataCopy = dataCopy.filter((it: any) => it._id !== id);
        setData(dataCopy);
        setLoading("");
        setOpen(false);
      } catch (error: any) {
        setLoading("");
        toast.error(error.message);
      }
    }
  };

  const exportSubjectHandler = async () => {
    try {
      setIsLoading(true);
      const res = await getClassSubjects(state?.classId);
      downloadExcel(res.data.data, state?.className + " subjects");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const columns = [
    {
      label: "Image",
      key: "Icon",
      content: (item: Subject) => (
        <Box
          component="img"
          sx={{
            height: 70,
            width: 100,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt={item.name}
          src={item.icon ? item.icon : "/assets/placeholder.jpg"}
        />
      ),
    },
    {
      label: "Name",
      path: "name",
    },
    {
      label: "Tag Line",
      path: "tagLine",
    },
    {
      label: "Language",
      path: "language",
    },
    {
      label: "",
      key: "ViewChapters",
      content: (item: Subject) => (
        <Link
          to="/chapters"
          state={{
            subjectId: item._id,
            subjectName: item.name,
            classId: state?.classId,
            className: state?.className,
          }}
        >
          <Button variant="outlined">View Chapters</Button>
        </Link>
      ),
    },
    {
      label: "",
      key: "Edit",
      content: (item: Subject) => (
        <Button onClick={() => editHandler(item)} variant="outlined">
          Edit
        </Button>
      ),
    },
    {
      label: "",
      key: "Delete",
      content: (item: Subject) => (
        <LoadingButton
          size="small"
          onClick={() => deleteHandler(item._id)}
          loading={loading !== "" && item._id?.includes(loading)}
          variant="contained"
          color="error"
        >
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];
  if (!state?.classId) {
    navigate("/classes");
  }

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Link to="/classes">
            <Button variant="contained">Back</Button>
          </Link>
          <Typography variant="h3">{state?.className} Subjects</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setItem(null);
              setOpen(true);
            }}
          >
            Add Subject
          </Button>
          <Button variant="contained" onClick={exportSubjectHandler}>
            Export Subjects
          </Button>
        </Box>
      </Box>
      <Table columns={columns} data={data} />
      <Box display="flex" justifyContent="start">
        <Pagination onChange={handleChange} count={totalPages} page={page} />
      </Box>
      <Modal open={open} setOpen={setOpen}>
        <SubjectForm
          subjectData={item}
          classId={state?.classId}
          setOpen={(value: boolean) => {
            setOpen(value);
            setIsLoading(true);
            getLimitedClassSubjects(state?.classId, page, LIMIT)
              .then((res: any) => {
                setData(res.data?.data);
                setTotalPages(res.data?.totalPages);
                setIsLoading(false);
              })
              .catch((err: any) => {
                setIsLoading(false);
                toast.error(err.message);
              });
          }}
        />
      </Modal>
    </Box>
  );
};
