import { TableCell, TableHead, TableRow } from "@mui/material";
import { Column } from "../../types";

interface ColumnsProps {
  data: Column[];
}
const Coloumns = ({ data }: ColumnsProps) => {
  return (
    <TableHead>
      <TableRow>
        {data.map((coloumn: Column) => (
          <TableCell key={coloumn.path || coloumn.key}>
            {coloumn.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default Coloumns;
