import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Table, Modal, Loader } from "../../Components";
import { PaperForm } from "./form";
import { deletePaper, getPapers } from "../../services";
import { toast } from "react-toastify";
import { PastPaper, Store } from "../../types";
import useStore from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { isSubject, isBoard } from "../../utils";

const LIMIT = 10;
export const PastPapers = () => {
  const { admin } = useStore((store: Store) => store);
  const [page, setPage] = React.useState<number>(1);
  const [item, setItem] = React.useState<PastPaper | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  React.useEffect(() => {
    getPapers(page, LIMIT)
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === page) return;
    setIsLoading(true);
    setPage(value);
  };

  const editHandler = (data: PastPaper) => {
    setItem({
      _id: data._id,
      subjectId: data.subjectId,
      image: data.image,
      boardId: data.boardId,
      year: data.year,
    });
    setOpen(true);
  };

  const deleteHandler = async (id: string | undefined) => {
    if (id) {
      const c = window.confirm("Are you sure to delete?");
      if (!c) return;
      try {
        setLoading(id);
        await deletePaper(id, admin ? admin : "");
        let dataCopy = [...data];
        dataCopy = dataCopy.filter((it: any) => it._id !== id);
        setData(dataCopy);
        setLoading("");
        setOpen(false);
      } catch (error: any) {
        setLoading("");
        toast.error(error.message);
      }
    }
  };

  const columns = [
    {
      label: "Image",
      key: "Icon",
      content: (item: PastPaper) => (
        <Box
          component="img"
          sx={{
            height: 70,
            width: 100,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="Paper"
          src={item.image ? item.image : "/assets/placeholder.jpg"}
        />
      ),
    },
    {
      label: "Subject",
      key: "Subject",
      content: (item: PastPaper) => (
        <Typography variant="body2">
          {isSubject(item.subjectId) && item.subjectId.name}
        </Typography>
      ),
    },
    {
      label: "Board",
      key: "Board",
      content: (item: PastPaper) => (
        <Typography variant="body2">
          {isBoard(item.boardId) && item.boardId.name}
        </Typography>
      ),
    },
    {
      label: "",
      key: "Edit",
      content: (item: PastPaper) => (
        <Button onClick={() => editHandler(item)} variant="outlined">
          Edit
        </Button>
      ),
    },
    {
      label: "",
      key: "Delete",
      content: (item: PastPaper) => (
        <LoadingButton
          size="small"
          onClick={() => deleteHandler(item._id)}
          loading={loading !== "" && item._id?.includes(loading)}
          variant="contained"
          color="error"
        >
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">PastPapers</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setItem(null);
            setOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <Table columns={columns} data={data} />
      <Box display="flex" justifyContent="start">
        <Pagination onChange={handleChange} count={totalPages} page={page} />
      </Box>
      <Modal open={open} setOpen={setOpen}>
        <PaperForm
          paperData={item}
          setOpen={(value: boolean) => {
            setOpen(value);
          }}
        />
      </Modal>
    </Box>
  );
};
