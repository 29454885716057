import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { CardWidget as Card } from "../../Components";
import useSWR from "swr";
import { getDashboardData } from "../../services";

export const Dashboard = () => {
  const { data } = useSWR("/get/dashboardData", getDashboardData);
  console.log(data);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            title="New Users"
            total={data?.data.data?.users}
            color="info"
            icon={"clarity:users-line"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            title="Boards"
            total={data?.data.data?.boards}
            color="warning"
            icon={"ion:home-outline"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            title="Classes"
            total={data?.data.data?.classes}
            color="success"
            icon={"healthicons:i-training-class"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
