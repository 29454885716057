import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { PastPaper, Store } from "../../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { addPastPaper, getPapersData, updatePastPaper } from "../../services";
import useStore from "../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { Loader, Select } from "../../Components";
import { isSubject, isBoard, paperYears } from "../../utils";
import useSWR from "swr";

interface PaperProps {
  setOpen: (data: boolean) => void;
  paperData: PastPaper | null;
}
export const PaperForm = ({ setOpen, paperData }: PaperProps) => {
  const { data, error } = useSWR("/get/all/papersdata", getPapersData);
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<PastPaper>({
    _id: paperData ? paperData._id : "",
    image: paperData && paperData.image ? paperData.image : "",
    year: paperData && paperData.year ? paperData.year : "",
    subjectId: paperData
      ? isSubject(paperData.subjectId) && paperData.subjectId._id
        ? paperData.subjectId._id
        : paperData.subjectId
      : "",
    boardId: paperData
      ? isBoard(paperData.boardId) && paperData.boardId._id
        ? paperData.boardId._id
        : paperData.boardId
      : "",
  });

  const validations = yup.object().shape({
    subjectId: yup.string().required(),
    boardId: yup.string().required(),
    year: yup.string().required(),
  });

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const clearForm = () => {
    setState({
      _id: "",
      subjectId: "",
      image: "",
      boardId: "",
      year: "",
    });
  };

  const handleSubmit = async (values: PastPaper) => {
    if (!state._id?.length && !image) {
      toast.error("Image is required!");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "subjectId",
        !isSubject(values.subjectId) ? values.subjectId : ""
      );
      formData.append(
        "boardId",
        !isBoard(values.boardId) ? values.boardId : ""
      );
      formData.append("year", values.year);
      if (image) {
        formData.append("file", image);
      }
      if (state._id?.length) {
        await updatePastPaper(state._id, formData, admin ? admin : "");
        toast.success("Updated sucessfully");
      } else {
        await addPastPaper(formData, admin ? admin : "");
        toast.success("Added sucessfully");
      }
      clearForm();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  if (error) {
    toast.error(error.message);
  }

  if (!data && !error) return <Loader />;
  return (
    <Box>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <Typography variant="h3">PastPaper Form</Typography>
              </Box>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "300%",
                  maxHeight: { xs: 233, md: 200 },
                  maxWidth: { xs: 350, md: 350 },
                  mb: 1,
                  borderRadius: 1,
                }}
                alt="SubjectImage"
                src={
                  image
                    ? URL.createObjectURL(image)
                    : state.image.length
                    ? state.image
                    : "/assets/placeholder.jpg"
                }
              />
              <Button variant="outlined" component="label">
                Upload File *
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    e.target.files && setImage(e.target.files[0])
                  }
                />
              </Button>

              <Select
                name="boardId"
                value={!isBoard(state.boardId) ? state.boardId : ""}
                label="Board *"
                changeHandler={changeHandler}
                data={data ? data.boards : []}
                searchKey="_id"
                searchValue="name"
              />
              <Select
                name="subjectId"
                value={!isSubject(state.subjectId) ? state.subjectId : ""}
                label="Subject *"
                changeHandler={changeHandler}
                data={data ? data.subjects : []}
                searchKey="_id"
                searchValue="name"
              />
              <Select
                name="year"
                value={state.year.length ? state.year : ""}
                label="Year *"
                changeHandler={changeHandler}
                data={paperYears}
                searchKey="name"
                searchValue="name"
              />
              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
