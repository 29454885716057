import { useState } from "react";
import Input from "../../Components/common/input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Board, Store } from "../../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { addBoard, updateBoard } from "../../services";
import useStore from "../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

interface BoardFormProps {
  setOpen: (data: boolean) => void;
  data: Board | null;
}
export const BoardForm = ({ setOpen, data }: BoardFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<Board>({
    _id: data ? data._id : "",
    name: data ? data.name : "",
  });

  const validations = yup.object().shape({
    name: yup.string().required(),
  });

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async (values: Board) => {
    try {
      setLoading(true);
      if (state._id?.length) {
        await updateBoard(state._id, values, admin ? admin : "");
        toast.success("Updated successfully");
      } else {
        await addBoard(values, admin ? admin : "");
        toast.success("Added successfully");
      }
      setLoading(false);
      setOpen(false);
      setState({ _id: "", name: "" });
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <Box>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box>
              <Box mb={3}>
                <Typography variant="h3">Board Form</Typography>
              </Box>
              <Input
                name="name"
                label="Name"
                value={state.name}
                changeHandler={changeHandler}
              />
              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
