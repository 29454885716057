import { useField, ErrorMessage } from "formik";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

interface InputProps {
  label: string;
  name: string;
  value?: string | number;
  type?: string;
  changeHandler?: (key: string, value: string) => void;
}
const Input = ({
  label,
  value,
  changeHandler,
  type = "text",
  ...props
}: InputProps) => {
  const [field, meta] = useField(props);
  useEffect(() => {
    changeHandler?.(field.name, meta.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.name, meta.value]);
  return (
    <Box width="100%">
      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel htmlFor={field.name}>{label}</InputLabel>
        <OutlinedInput
          label={label}
          type={type}
          value={value}
          className={`${meta.error && "is-invalid"}`}
          {...field}
          {...props}
        />
        <ErrorMessage
          name={field.name}
          component="div"
          style={{ color: "red" }}
        />
      </FormControl>
    </Box>
  );
};

export default Input;
