import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Table, Modal, Loader } from "../../Components";
import { FAQForm } from "./form";
import { deleteFaq, getFaqs } from "../../services";
import { toast } from "react-toastify";
import { FAQ, Store } from "../../types";
import useStore from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";

const LIMIT = 10;
export const FAQs = () => {
  const { admin } = useStore((store: Store) => store);
  const [page, setPage] = React.useState<number>(1);
  const [item, setItem] = React.useState<FAQ | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  React.useEffect(() => {
    getFaqs(page, LIMIT)
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === page) return;
    setIsLoading(true);
    setPage(value);
  };

  const editHandler = (data: FAQ) => {
    setItem({
      _id: data._id,
      question: data.question,
      answer: data.answer,
      index: data.index,
    });
    setOpen(true);
  };

  const deleteHandler = async (id: string | undefined) => {
    if (id) {
      const c = window.confirm("Are you sure to delete?");
      if (!c) return;
      try {
        setLoading(id);
        await deleteFaq(id, admin ? admin : "");
        let dataCopy = [...data];
        dataCopy = dataCopy.filter((it: any) => it._id !== id);
        setData(dataCopy);
        setLoading("");
        setOpen(false);
      } catch (error: any) {
        setLoading("");
        toast.error(error.message);
      }
    }
  };

  const columns = [
    {
      label: "Question",
      path: "question",
    },
    {
      label: "Answer",
      path: "answer",
    },
    {
      label: "Index",
      path: "index",
    },
    {
      label: "",
      key: "Edit",
      content: (item: FAQ) => (
        <Button onClick={() => editHandler(item)} variant="outlined">
          Edit
        </Button>
      ),
    },
    {
      label: "",
      key: "Delete",
      content: (item: FAQ) => (
        <LoadingButton
          size="small"
          onClick={() => deleteHandler(item._id)}
          loading={loading !== "" && item._id?.includes(loading)}
          variant="contained"
          color="error"
        >
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">FAQs</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(true);
            setItem(null);
          }}
        >
          Add
        </Button>
      </Box>
      <Table columns={columns} data={data} />
      <Box display="flex" justifyContent="start">
        <Pagination onChange={handleChange} count={totalPages} page={page} />
      </Box>
      <Modal open={open} setOpen={setOpen}>
        <FAQForm
          data={item}
          setOpen={(value: boolean) => {
            setOpen(value);
            setIsLoading(true);
            getFaqs(page, LIMIT)
              .then((res: any) => {
                setData(res.data?.data);
                setTotalPages(res.data?.totalPages);
                setIsLoading(false);
              })
              .catch((err: any) => {
                setIsLoading(false);
                toast.error(err.message);
              });
          }}
        />
      </Modal>
    </Box>
  );
};
