import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Table, Modal, Loader } from "../../Components";
import { ChapterForm } from "./form";
import {
  deleteChapter,
  getPaginatedSubjectChapters,
  getSubjectChapters,
} from "../../services";
import { toast } from "react-toastify";
import { Chapter, Store } from "../../types";
import useStore from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { downloadExcel, isSubject } from "../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";

const LIMIT = 10;
export const Chapters = () => {
  const { state } = useLocation();
  const { admin } = useStore((store: Store) => store);
  const [page, setPage] = React.useState<number>(1);
  const [item, setItem] = React.useState<Chapter | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    getPaginatedSubjectChapters(state?.subjectId, page, LIMIT)
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  }, [state?.subjectId, page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === page) return;
    setIsLoading(true);
    setPage(value);
  };

  const editHandler = (data: Chapter) => {
    setItem({
      _id: data._id,
      name: data.name,
      language: data.language,
      subjectId: data.subjectId,
      classId: data.classId,
      chapterIndex: data.chapterIndex,
    });
    setOpen(true);
  };

  const deleteHandler = async (id: string | undefined) => {
    if (id) {
      const c = window.confirm("Are you sure to delete?");
      if (!c) return;
      try {
        setLoading(id);
        await deleteChapter(id, admin ? admin : "");
        let dataCopy = [...data];
        dataCopy = dataCopy.filter((it: any) => it._id !== id);
        setData(dataCopy);
        setLoading("");
        setOpen(false);
      } catch (error: any) {
        setLoading("");
        toast.error(error.message);
      }
    }
  };

  const exportChaptersHandler = async () => {
    try {
      setIsLoading(true);
      const res = await getSubjectChapters(state?.subjectId);
      downloadExcel(res.data.data, state?.subjectName + " Chapters");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const columns = [
    {
      label: "Name",
      path: "name",
    },
    {
      label: "Chapter Number",
      path: "chapterIndex",
    },
    {
      label: "Language",
      path: "language",
    },
    {
      label: "Subject",
      key: "Subject",
      content: (item: Chapter) => (
        <Typography variant="body2">
          {isSubject(item.subjectId) && item.subjectId.name}
        </Typography>
      ),
    },
    {
      label: "",
      key: "ViewChapters",
      content: (item: Chapter) => (
        <Link
          to="/questions"
          state={{
            chapterId: item._id,
            classId: state?.classId,
            subjectId: state?.subjectId,
          }}
        >
          <Button variant="outlined">View Questions</Button>
        </Link>
      ),
    },
    {
      label: "",
      key: "Edit",
      content: (item: Chapter) => (
        <Button onClick={() => editHandler(item)} variant="outlined">
          Edit
        </Button>
      ),
    },
    {
      label: "",
      key: "Delete",
      content: (item: Chapter) => (
        <LoadingButton
          size="small"
          onClick={() => deleteHandler(item._id)}
          loading={loading !== "" && item._id?.includes(loading)}
          variant="contained"
          color="error"
        >
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];

  if (!state?.classId || !state?.subjectId) {
    navigate("/classes");
  }

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Link
            to="/subjects"
            state={{
              classId: state?.classId,
              className: state?.className,
            }}
          >
            <Button variant="contained">Back</Button>
          </Link>
          <Typography variant="h3">
            {state?.className + "," + state?.subjectName} Chapters
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setItem(null);
              setOpen(true);
            }}
          >
            Add Chapter
          </Button>
          <Button variant="contained" onClick={exportChaptersHandler}>
            Export Chapters
          </Button>
        </Box>
      </Box>
      <Table columns={columns} data={data} />
      <Box display="flex" justifyContent="start">
        <Pagination onChange={handleChange} count={totalPages} page={page} />
      </Box>
      <Modal open={open} setOpen={setOpen}>
        <ChapterForm
          classId={state?.classId}
          subjectId={state?.subjectId}
          chapterData={item}
          setOpen={(value: boolean) => {
            setOpen(value);
            setIsLoading(true);
            getPaginatedSubjectChapters(state?.subjectId, page, LIMIT)
              .then((res: any) => {
                setData(res.data?.data);
                setTotalPages(res.data?.totalPages);
                setIsLoading(false);
              })
              .catch((err: any) => {
                setIsLoading(false);
                toast.error(err.message);
              });
          }}
        />
      </Modal>
    </Box>
  );
};
