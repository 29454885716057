import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Table, Modal, Loader } from "../../../Components";
import { MCQsQuestionForm } from "./form";
import { deleteMCQS, getChapterMCQS } from "../../../services";
import { toast } from "react-toastify";
import { MCQS, Store } from "../../../types";
import useStore from "../../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { isChapter } from "../../../utils";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const LIMIT = 10;
export const MCQSQuestion = () => {
  const {
    state: { classId, subjectId, chapterId },
  } = useLocation();
  const { admin } = useStore((store: Store) => store);
  const [page, setPage] = React.useState<number>(1);
  const [item, setItem] = React.useState<MCQS | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  React.useEffect(() => {
    getChapterMCQS(chapterId, page, LIMIT)
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  }, [chapterId, page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === page) return;
    setIsLoading(true);
    setPage(value);
  };

  const editHandler = (data: MCQS) => {
    setItem({
      _id: data._id,
      question: data.question,
      answers: data.answers,
      correctAnswer: data.correctAnswer,
      serialNumber: data.serialNumber,
      chapterId: data.chapterId,
      language: data.language,
      classId: data.classId,
      subjectId: data.subjectId,
    });
    setOpen(true);
  };

  const deleteHandler = async (id: string | undefined) => {
    if (id) {
      const c = window.confirm("Are you sure to delete?");
      if (!c) return;
      try {
        setLoading(id);
        await deleteMCQS(id, admin ? admin : "");
        let dataCopy = [...data];
        dataCopy = dataCopy.filter((it: any) => it._id !== id);
        setData(dataCopy);
        setLoading("");
        setOpen(false);
      } catch (error: any) {
        setLoading("");
        toast.error(error.message);
      }
    }
  };

  const columns = [
    {
      label: "Question",
      path: "question",
    },
    {
      label: "Correct Answer",
      path: "correctAnswer",
    },
    {
      label: "Serial Number",
      path: "serialNumber",
    },
    {
      label: "Language",
      path: "language",
    },
    {
      label: "Chapter",
      key: "Chapter",
      content: (item: MCQS) => (
        <Typography variant="body2">
          {isChapter(item.chapterId) && item.chapterId.name}
        </Typography>
      ),
    },
    {
      label: "",
      key: "View",
      content: (item: MCQS) => (
        <Button variant="outlined">
          <Link to="/mcqsquestion" state={item}>
            View
          </Link>
        </Button>
      ),
    },
    {
      label: "",
      key: "Edit",
      content: (item: MCQS) => (
        <Button onClick={() => editHandler(item)} variant="outlined">
          Edit
        </Button>
      ),
    },
    {
      label: "",
      key: "Delete",
      content: (item: MCQS) => (
        <LoadingButton
          size="small"
          onClick={() => deleteHandler(item._id)}
          loading={loading !== "" && item._id?.includes(loading)}
          variant="contained"
          color="error"
        >
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">MCQs</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setItem(null);
            setOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      {data?.length ? (
        <Table columns={columns} data={data} />
      ) : (
        <Box my={5} fontSize={17}>
          There is no data.
        </Box>
      )}
      <Box display="flex" justifyContent="start">
        <Pagination onChange={handleChange} count={totalPages} page={page} />
      </Box>
      <Modal open={open} setOpen={setOpen} width={700}>
        <MCQsQuestionForm
          questiontData={item}
          classId={classId}
          subjectId={subjectId}
          chapterId={chapterId}
          setOpen={(value: boolean) => {
            setOpen(value);
            setIsLoading(true);
            getChapterMCQS(chapterId, page, LIMIT)
              .then((res: any) => {
                setData(res.data?.data);
                setTotalPages(res.data?.totalPages);
                setIsLoading(false);
              })
              .catch((err: any) => {
                setIsLoading(false);
                toast.error(err.message);
              });
          }}
        />
      </Modal>
    </Box>
  );
};
