import { useState } from "react";
import Input from "../../Components/common/input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FAQ, Store } from "../../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { addFaq, updateFaq } from "../../services";
import useStore from "../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

interface FormProps {
  setOpen: (data: boolean) => void;
  data: FAQ | null;
}
export const FAQForm = ({ setOpen, data }: FormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<FAQ>({
    _id: data ? data._id : "",
    question: data ? data.question : "",
    answer: data ? data.answer : "",
    index: data ? data.index : 0,
  });

  const validations = yup.object().shape({
    question: yup.string().required(),
    index: yup.number().min(1),
  });

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async (values: FAQ) => {
    try {
      setLoading(true);
      if (state._id?.length) {
        await updateFaq(state._id, values, admin ? admin : "");
        toast.success("Updated successfully");
      } else {
        await addFaq(values, admin ? admin : "");
        toast.success("Added successfully");
      }
      setLoading(false);
      setOpen(false);
      setState({ _id: "", question: "", answer: "", index: 0 });
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <Box>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box>
              <Box mb={3}>
                <Typography variant="h3">FAQ Form</Typography>
              </Box>
              <Input
                name="question"
                label="Question"
                value={state.question}
                changeHandler={changeHandler}
              />
              <Input
                name="answer"
                label="Answer"
                value={state.answer}
                changeHandler={changeHandler}
              />
              <Input
                name="index"
                label="Index"
                type="number"
                value={state.index}
                changeHandler={changeHandler}
              />
              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
