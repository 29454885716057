import { Board, Chapter, Class, Subject } from "../types";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

// export const API_URL = "http://localhost:5000/api/";
export const API_URL = "http://3.108.62.100/api/";

export function isObject(obj: any): boolean {
  return obj && typeof obj === "object" && "name" in obj;
}
export function isClass(obj: any): obj is Class {
  return obj && typeof obj === "object" && "name" in obj;
}
export function isSubject(obj: any): obj is Subject {
  return obj && typeof obj === "object" && "name" in obj;
}
export function isChapter(obj: any): obj is Chapter {
  return obj && typeof obj === "object" && "name" in obj;
}
export function isBoard(obj: any): obj is Board {
  return obj && typeof obj === "object" && "name" in obj;
}

export const paperYears = [
  { name: "2015" },
  { name: "2016" },
  { name: "2017" },
  { name: "2018" },
  { name: "2019" },
  { name: "2020" },
  { name: "2021" },
  { name: "2022" },
  { name: "2023" },
];
export const LANGUAGES = [{ name: "english" }, { name: "urdu" }];

export function downloadExcel(data: any, sheetName: any) {
  const wb = XLSX.utils.book_new();
  /* create a worksheet for data */
  const wsData = XLSX.utils.json_to_sheet(data);
  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, wsData, sheetName);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data1 = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data1, sheetName + ".xlsx");
}
