import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ShortQuestions } from "./ShortQuestion";
import { LongQuestions } from "./LongQuestion";
import { MCQSQuestion } from "./MCQS";
import { useLocation, useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { toast } from "react-toastify";
import useStore from "../../store";
import { addFillintheblank, addLongQuestion, addMCQs, addNumerical, addQuestion, addShortQuestion } from "../../services";
import { Store } from "../../types";
import { LoadingButton } from "@mui/lab";
import { SingleQuestions } from "./Question";
import { FillIntTheBlanks } from "./Fillintheblanks";
import { Numericals } from "./Numerical";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const Questions = () => {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [questionType, setQuestionType] = React.useState("question");
  const [loading, setLoading] = React.useState<boolean>(false);
  const { admin } = useStore((store: Store) => store);
  const inputRef = React.useRef<any>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setQuestionType("question");
    } else if (newValue === 1) {
      setQuestionType("short");
    } else if (newValue === 2) {
      setQuestionType("long");
    } else if (newValue === 3) {
      setQuestionType("mcq");
    } else if (newValue === 4) {
      setQuestionType("numerical");
    } else if (newValue === 5) {
      setQuestionType("fillintheblank");
    }
  };

  const handleChangeIndex = (index: number) => {
    if (index === 0) {
      setQuestionType("question");
    } else if (index === 1) {
      setQuestionType("short");
    } else if (index === 2) {
      setQuestionType("long");
    } else if (index === 3) {
      setQuestionType("mcq");
    } else if (index === 4) {
      setQuestionType("numerical");
    } else if (index === 5) {
      setQuestionType("fillintheblank");
    }
    setValue(index);
  };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    setLoading(true);
    readXlsxFile(fileObj)
      .then(async (questions) => {
        if (!state.classId || !state.subjectId || !state.chapterId) {
          toast.error("class, subject, chapter are required");
          return;
        }
        if (questionType.includes("short")
          || questionType.includes("long")
          || questionType.includes("question")
          || questionType.includes("numerical")
        ) {
          for (let i = 0; i < questions.length; i++) {
            if (i > 0) {
              const item: any = questions[i];
              const question = {
                question: item[0],
                answer: item[1],
                serialNumber: item[2],
                language: item[3],
                classId: state?.classId,
                subjectId: state?.subjectId,
                chapterId: state?.chapterId,
              };
              if (questionType.includes("short")) {
                await addShortQuestion(question, admin ? admin : "");
              } else if (questionType.includes("long")) {
                await addLongQuestion(question, admin ? admin : "");
              } else if (questionType.includes("question")) {
                await addQuestion(question, admin ? admin : "");
              } else if (questionType.includes("numerical")) {
                await addNumerical(question, admin ? admin : "");
              }
            }
          } 
        } else if (questionType.includes("mcq")) {
          for (let i = 0; i < questions.length; i++) {
            if (i > 0) {
              const item: any = questions[i];
              let answers = item[1];
              answers = answers.replace(/'/g, '"');
              answers = JSON.parse(answers);
              const question = {
                question: item[0],
                answers: answers,
                correctAnswer: item[2],
                serialNumber: item[3],
                language: item[4],
                classId: state?.classId,
                subjectId: state?.subjectId,
                chapterId: state?.chapterId,
              };
              await addMCQs(question, admin ? admin : "");
            }
          }
        } else if (questionType.includes("fillintheblank")) {
          for (let i = 0; i < questions.length; i++) {
            if (i > 0) {
              const item: any = questions[i];
              const question = {
                question: item[0],
                serialNumber: item[1],
                language: item[2],
                classId: state?.classId,
                subjectId: state?.subjectId,
                chapterId: state?.chapterId,
              };
              await addFillintheblank(question, admin ? admin : "");
            }
          }

        }
        setLoading(false);
        toast.success("successfully imported all the Questions.");
        window.location.replace("/classes");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };
  const handleImportQuestions = () => {
    inputRef.current?.click();
  };

  if (!state?.classId || !state?.subjectId || !state?.chapterId) {
    navigate("/classes");
  }

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="Questions" {...a11yProps(0)} />
          <Tab label="Short Questions" {...a11yProps(1)} />
          <Tab label="Long Questions" {...a11yProps(2)} />
          <Tab label="MCQS" {...a11yProps(3)} />
          <Tab label="Numericals" {...a11yProps(4)} />
          <Tab label="Fill In The Blanks" {...a11yProps(5)} />
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              variant="outlined"
              onClick={handleImportQuestions}
              type="submit"
            >
              <span>Import Questions</span>
            </LoadingButton>
          </Box>
          <SingleQuestions />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              variant="outlined"
              onClick={handleImportQuestions}
              type="submit"
            >
              <span>Import Short Questions</span>
            </LoadingButton>
          </Box>
          <ShortQuestions />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              variant="outlined"
              onClick={handleImportQuestions}
              type="submit"
            >
              <span>Import Long Questions</span>
            </LoadingButton>
          </Box>
          <LongQuestions />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              variant="outlined"
              onClick={handleImportQuestions}
              type="submit"
            >
              <span>Import MCQs</span>
            </LoadingButton>
          </Box>
          <MCQSQuestion />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              variant="outlined"
              onClick={handleImportQuestions}
              type="submit"
            >
              <span>Import Numericals</span>
            </LoadingButton>
          </Box>
          <Numericals />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              variant="outlined"
              onClick={handleImportQuestions}
              type="submit"
            >
              <span>Import fill in the blanks</span>
            </LoadingButton>
          </Box>
          <FillIntTheBlanks />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
