import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

export const QuestionView = () => {
  const { state } = useLocation();
  return (
    <Box>
      <Box
        mb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h1">{state?.classId?.name}</Typography>
        <Box>
          <Typography>Language: {state?.language}</Typography>
          <Typography>Serial Number: {state?.serialNumber}</Typography>
        </Box>
      </Box>
      <Typography variant="h2" mb={3}>
        Subject: {state?.subjectId?.name}
      </Typography>
      <Typography variant="h3" mb={3}>
        Chapter: {state?.chapterId?.name}
      </Typography>
      <Typography variant="h6" mb={3}>
        {state?.question}
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: state?.answer }} />
    </Box>
  );
};
