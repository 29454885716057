import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";

export const MCQsView = () => {
  const { state } = useLocation();
  console.log(state);
  return (
    <Box>
      <Box
        mb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h1">{state?.classId?.name}</Typography>
        <Box>
          <Typography>Language: {state?.language}</Typography>
          <Typography>Serial Number: {state?.serialNumber}</Typography>
        </Box>
      </Box>
      <Typography variant="h2" mb={3}>
        Subject: {state?.subjectId?.name}
      </Typography>
      <Typography variant="h3" mb={3}>
        Chapter: {state?.chapterId?.name}
      </Typography>
      <Typography variant="h5" mb={3}>
        {state?.question}
      </Typography>
      <Typography variant="h6" mb={3}>
        Answers:
      </Typography>

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        aria-label="contacts"
      >
        {state?.answers.map((answer: string, index: number) => (
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={answer} />
              {state?.correctAnswer === index + 1 ? (
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
              ) : (
                ""
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
