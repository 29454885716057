import { useEffect } from "react";
import { useField, ErrorMessage } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

interface SelectProps {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  searchKey: string;
  searchValue: string;
  data: any[];
  changeHandler?: (name: string, value: string) => void;
}
export const SelectComponent = (props: SelectProps) => {
  const [field, meta] = useField(props);

  useEffect(() => {
    props.changeHandler?.(field.name, meta.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.value]);

  return (
    <FormControl sx={{ ml: 2, my: 1, minWidth: 120, width: "100%" }}>
      <InputLabel htmlFor={field.name}>{props.label}</InputLabel>
      <Select
        {...field}
        {...props}
        id={field.name}
        value={props.value}
        label={props.label}
        className={`${meta.error && "is-invalid"}`}
      >
        <MenuItem value="">Search {props.label}</MenuItem>
        {props.data.map((e, i) => (
          <MenuItem key={i} value={e[props.searchKey]}>
            {e[props.searchValue]}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage
        name={field.name}
        component="div"
        style={{ color: "red" }}
      />
    </FormControl>
  );
};
