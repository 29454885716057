import { useState } from "react";
import Input from "../../../Components/common/input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Question, Store } from "../../../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import {
  addShortQuestion,
  getAllClasses,
  updateShortQuestion,
} from "../../../services";
import useStore from "../../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Loader, Select } from "../../../Components";
import useSWR from "swr";
import RichEditor from "../../../Components/common/richTextEditor";
import { useLocation, useNavigate } from "react-router-dom";
import { LANGUAGES } from "../../../utils";

export const ShortQuestionForm = () => {
  const { data, error } = useSWR("/get/all/classes", getAllClasses);
  const [loading, setLoading] = useState<boolean>(false);
  const { admin } = useStore((store: Store) => store);
  const {
    state: { questionData, classId, subjectId, chapterId },
  } = useLocation();
  const navigate = useNavigate();
  const [formState, setFormState] = useState<Question>({
    _id: questionData ? questionData._id : "",
    question: questionData ? questionData.question : "",
    answer: questionData ? questionData.answer : "",
    language: questionData ? questionData.language : LANGUAGES[0].name,
    serialNumber: questionData ? questionData.serialNumber : 0,
  });

  const validations = yup.object().shape({
    question: yup.string().required(),
    language: yup.string().required(),
    serialNumber: yup.number().min(1),
  });

  const changeHandler = (key: string, value: string) => {
    setFormState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const clearForm = () => {
    setFormState({
      _id: "",
      question: "",
      answer: "",
      language: LANGUAGES[0].name,
      serialNumber: 0,
    });
  };

  const handleSubmit = async (values: Question) => {
    try {
      if (!formState.answer.length) return toast.error("Answer is required.");
      setLoading(true);
      if (formState._id?.length) {
        await updateShortQuestion(
          formState._id,
          {
            ...values,
            classId,
            subjectId,
            chapterId,
            answer: formState.answer,
          },
          admin ? admin : ""
        );
        toast.success("Updated sucessfully");
      } else {
        await addShortQuestion(
          {
            ...values,
            classId,
            subjectId,
            chapterId,
            answer: formState.answer,
          },
          admin ? admin : ""
        );
        toast.success("Added sucessfully");
      }
      clearForm();
      setLoading(false);
      navigate("/questions", {
        state: {
          questionData,
          classId,
          subjectId,
          chapterId,
        },
      });
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  if (error) {
    toast.error(error.message);
  }

  if (!data && !error) return <Loader />;
  return (
    <Box>
      <Formik
        initialValues={formState}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <Typography variant="h3">Short Question Form</Typography>
              </Box>
              <Input
                name="question"
                label="Question *"
                value={formState.question}
                changeHandler={changeHandler}
              />
              <Box ml={2} my={1} width="100%">
                <RichEditor
                  name="answer"
                  label="Answer"
                  value={formState.answer}
                  textHandler={changeHandler}
                />
              </Box>
              <Input
                type="number"
                name="serialNumber"
                label="Serial Number *"
                value={formState.serialNumber}
                changeHandler={changeHandler}
              />
              <Select
                name="language"
                label="Language"
                value={formState.language}
                searchKey="name"
                placeholder="Select Language"
                searchValue="name"
                data={LANGUAGES}
                changeHandler={changeHandler}
              />

              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
