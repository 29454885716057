import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface RichEditorProps {
  name: string;
  label: string;
  value: string | null;
  textHandler: (key: string, value: string) => void;
}
const RichTextEditor = ({
  label,
  name,
  textHandler,
  value = "",
}: RichEditorProps) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toolbarConfig = {
    inline: {
      visible: true,
      inDropdown: false,
      bold: { visible: true },
      italic: { visible: true },
      underline: { visible: true },
      strikeThrough: { visible: true },
      monospace: { visible: true },
    },
    blockType: { visible: true },
    fontSize: { visible: true },
    fontFamily: {
      visible: true,
      options: [
        "Impact",
        "Open Sans",
        "Roboto",
        "Tahoma",
        "Times New Roman",
        "Verdana",
        "Mulish",
        "Poppins",
        "Noto Nastaliq Urdu",
      ],
    },
    list: {
      visible: true,
      inDropdown: true,
      unordered: { visible: true },
      ordered: { visible: true },
      indent: { visible: true },
      outdent: { visible: true },
    },
    textAlign: {
      visible: true,
      inDropdown: true,
      left: { visible: true },
      center: { visible: true },
      right: { visible: true },
      justify: { visible: true },
    },
    colorPicker: { visible: true },
    link: {
      visible: true,
      inDropdown: true,
      addLink: { visible: true },
      removeLink: { visible: true },
    },
    image: {
      visible: true,

      fileUpload: true,
      url: true,
    },
    history: {
      visible: true,
      inDropdown: true,
      undo: { visible: true },
      redo: { visible: true },
    },
  };

  return (
    <Box mb={3}>
      <Box component="label">{label}</Box>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Mulish&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu&display=swap"
          rel="stylesheet"
        ></link>
      </head>
      <Editor
        toolbar={toolbarConfig}
        editorState={editorState}
        wrapperClassName="rich-editor-wrapper"
        onEditorStateChange={(newState: any) => {
          setEditorState(newState);
          textHandler(
            name,
            draftToHtml(convertToRaw(newState.getCurrentContent()))
          );
        }}
      />
    </Box>
  );
};

export default RichTextEditor;
