import { Box, Button, Typography } from "@mui/material";
import { Table } from "../../Components";
import { LoadingButton } from "@mui/lab";

export const Users = () => {
  const users = [{ name: "Dk", email: "dk@gmail.com" }];
  const columns = [
    {
      label: "Name",
      path: "name",
    },
    {
      label: "Email",
      path: "email",
    },
    {
      label: "",
      key: "Edit",
      content: (item: any) => <Button variant="outlined">Edit</Button>,
    },
    {
      label: "",
      key: "Delete",
      content: (item: any) => (
        <LoadingButton size="small" variant="contained" color="error">
          <span>Delete</span>
        </LoadingButton>
      ),
    },
  ];
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Users</Typography>
      </Box>
      <Table columns={columns} data={users} />
    </Box>
  );
};
