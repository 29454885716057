import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Box, Card, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Admin, Store } from "../../types";
import Input from "../../Components/common/input";
import SendIcon from "@mui/icons-material/Send";
import { loginAdmin } from "../../services";
import useStore from "../../store";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

export const Login = () => {
  const { setAdmin, admin } = useStore((store: Store) => store);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [state] = React.useState<Admin>({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const validations = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
  });

  const handleSubmit = async (values: Admin) => {
    try {
      setLoading(true);
      const response = await loginAdmin(values);
      sessionStorage.setItem("parho-admin", JSON.stringify(response.data.data));
      setAdmin(response.data.data);
      navigate("/dashboard");
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.errors[0].msg);
      setLoading(false);
    }
  };
  if (admin) return <Navigate to="/dashboard" />;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Card
        sx={{ maxWidth: 500, width: "100%", padding: 2 }}
        variant="outlined"
      >
        <Formik
          initialValues={state}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Box>
                <Box mb={3}>
                  <Typography variant="h3">Admin Login</Typography>
                </Box>
                <Box maxWidth={450}>
                  <Input name="email" label="Email" />
                  <Input name="password" label="Password" type="password" />
                </Box>
                <Box mt={2}>
                  <LoadingButton
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="contained"
                    type="submit"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Box>
  );
};
