import { forwardRef } from "react";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";

interface IconifyProps {
  sx?: object;
  width: number | string;
  height: number | string;
  icon: string;
}
const Iconify = forwardRef(
  ({ icon, width = 20, sx, ...other }: IconifyProps, ref) => (
    <Box
      ref={ref}
      component={Icon}
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
);

export default Iconify;
