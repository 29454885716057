import _ from "lodash";
import { Column } from "../../types";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

interface TableBodyProps {
  data: any[];
  columns: Column[];
}
const Body = ({ columns, data }: TableBodyProps) => {
  const renderCell = (item: any, column: Column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path ? column.path : "");
  };

  const createKey = (item: any, column: Column) => {
    return item.id + (column.label || column.key || column.path);
  };
  return (
    <TableBody>
      {data.map((item) => (
        <TableRow
          key={item.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          {columns.map((column: Column) => (
            <TableCell key={createKey(item, column)}>
              {renderCell(item, column)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default Body;
