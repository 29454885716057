import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Box from "@mui/material/Box";
import {
  AppSetting,
  Boards,
  Chapters,
  Classes,
  Dashboard,
  FAQs,
  Login,
  LongQuestionForm,
  MCQsView,
  PastPapers,
  QuestionView,
  Questions,
  ShortQuestionForm,
  Subjects,
  Users,
} from "../Pages";
import { Sidebar } from "../Components";
import useStore from "../store";
import { Store } from "../types";
import { ProtectedRoute } from "../Components/ProtectedRoute";
import { QuestionForm } from "../Pages/Questions/Question/form";
import { FillInTheBlankForm } from "../Pages/Questions/Fillintheblanks/form";
import { NumericalForm } from "../Pages/Questions/Numerical/form";

const Index = () => {
  const { admin } = useStore((state: Store) => state);
  return (
    <Box>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Sidebar>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Dashboard />}
                />
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Users />}
                />
              }
            />
            <Route
              path="/boards"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Boards />}
                />
              }
            />
            <Route
              path="/classes"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Classes />}
                />
              }
            />
            <Route
              path="/subjects"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Subjects />}
                />
              }
            />
            <Route
              path="/chapters"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Chapters />}
                />
              }
            />
            <Route
              path="/questions"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<Questions />}
                />
              }
            />
            <Route
              path="/shortquestionform"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<ShortQuestionForm />}
                />
              }
            />
            <Route
              path="/viewquestion"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<QuestionView />}
                />
              }
            />
            <Route
              path="/mcqsquestion"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<MCQsView />}
                />
              }
            />
            <Route
              path="/longquestionform"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<LongQuestionForm />}
                />
              }
            />
            <Route
              path="/questionform"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<QuestionForm />}
                />
              }
            />
            <Route
              path="/fillintheblankform"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<FillInTheBlankForm />}
                />
              }
            />
            <Route
              path="/numericalform"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<NumericalForm />}
                />
              }
            />
            <Route
              path="/past-papers"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<PastPapers />}
                />
              }
            />
            <Route
              path="/faqs"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<FAQs />}
                />
              }
            />
            <Route
              path="/setting"
              element={
                <ProtectedRoute
                  isAuthenticated={!!admin}
                  authenticationPath="/login"
                  outlet={<AppSetting />}
                />
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Sidebar>
      </Router>
    </Box>
  );
};

export default Index;
