import { useState } from "react";
import Input from "../../../Components/common/input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { MCQS, Store } from "../../../types";
import Box from "@mui/material/Box";
import { Button, Card, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { addMCQs, getAllClasses, updateMCQS } from "../../../services";
import useStore from "../../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Loader, Select } from "../../../Components";
import useSWR from "swr";
import DeleteIcon from "@mui/icons-material/Delete";
import { LANGUAGES } from "../../../utils";

interface QuestionsFormProps {
  setOpen: (data: boolean) => void;
  questiontData: MCQS | null;
  classId: string;
  subjectId: string;
  chapterId: string;
}
export const MCQsQuestionForm = ({
  setOpen,
  questiontData,
  classId,
  subjectId,
  chapterId,
}: QuestionsFormProps) => {
  const { data, error } = useSWR("/get/all/classes", getAllClasses);
  const [loading, setLoading] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("");
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<MCQS>({
    _id: questiontData ? questiontData._id : "",
    question: questiontData ? questiontData.question : "",
    answers: questiontData ? questiontData.answers : [],
    correctAnswer: questiontData ? questiontData.correctAnswer : 0,
    language: questiontData ? questiontData.language : LANGUAGES[0].name,
    serialNumber: questiontData ? questiontData.serialNumber : 0,
  });

  const validations = yup.object().shape({
    question: yup.string().required(),
    language: yup.string().required(),
    correctAnswer: yup.number().min(1),
    serialNumber: yup.number().min(1),
  });

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const addAnswer = () => {
    const answers = [...state.answers];
    answers.push(answer);
    setState((p) => ({
      ...p,
      answers: answers,
    }));
    setAnswer("");
  };
  const deleteAnswer = (index: number) => {
    const answers = [...state.answers];
    answers.splice(index, 1);
    setState((p) => ({
      ...p,
      answers: answers,
    }));
  };
  const clearForm = () => {
    setState({
      _id: "",
      question: "",
      answers: [],
      correctAnswer: 0,
      language: LANGUAGES[0].name,
      serialNumber: 0,
    });
  };

  const handleSubmit = async (values: MCQS) => {
    try {
      if (state.answers.length < 2) {
        toast.error("At least 2 answers are required.");
        return;
      }
      setLoading(true);
      if (state._id?.length) {
        await updateMCQS(
          state._id,
          { ...values, classId, subjectId, chapterId, answers: state.answers },
          admin ? admin : ""
        );
        toast.success("Updated sucessfully");
      } else {
        await addMCQs(
          { ...values, classId, subjectId, chapterId, answers: state.answers },
          admin ? admin : ""
        );
        toast.success("Added sucessfully");
      }
      clearForm();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  if (error) {
    toast.error(error.message);
  }

  if (!data && !error) return <Loader />;
  return (
    <Box>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <Typography variant="h3">MCQS Form</Typography>
              </Box>
              <Input
                name="question"
                label="Question *"
                value={state.question}
                changeHandler={changeHandler}
              />
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  width: "100%",
                }}
                gap={2}
              >
                <Input
                  name="answer"
                  label="Answer *"
                  value={answer}
                  changeHandler={(key: string, value: string) =>
                    setAnswer(value)
                  }
                />
                <Button
                  disabled={answer === ""}
                  variant="outlined"
                  onClick={addAnswer}
                >
                  Add
                </Button>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                gap={1}
                sx={{ width: " 100%", mt: 1, mb: 2 }}
              >
                {state.answers.map((item: string, index: number) => (
                  <Card>
                    <Box px={1} display="flex" alignItems="center" gap={1}>
                      <Box>{item}</Box>
                      <Button
                        onClick={() => deleteAnswer(index)}
                        color="error"
                        sx={{ cursor: "pointer" }}
                        startIcon={<DeleteIcon />}
                        variant="text"
                      />
                    </Box>
                  </Card>
                ))}
              </Box>
              <Input
                type="number"
                name="correctAnswer"
                label="Correct Answer *"
                value={state.correctAnswer}
                changeHandler={changeHandler}
              />
              <Input
                type="number"
                name="serialNumber"
                label="Serial Number *"
                value={state.serialNumber}
                changeHandler={changeHandler}
              />
              <Select
                name="language"
                label="Language"
                value={state.language}
                searchKey="name"
                placeholder="Select Language"
                searchValue="name"
                data={LANGUAGES}
                changeHandler={changeHandler}
              />
              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
