import { create } from "zustand";
import { Store } from "../types";
const loggedAdmin = sessionStorage.getItem("parho-admin");

const useStore = create<Store>((set) => ({
  admin: loggedAdmin ? JSON.parse(loggedAdmin) : null,
  setAdmin: (token: string | null) => {
    set((state: any) => {
      return {
        ...state,
        admin: token,
      };
    });
  },
}));

export default useStore;
