import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Column } from "../../types";
import Coloumns from "./columns";
import Body from "./body";

interface TableProps {
  data: any[];
  columns: Column[];
}

export const MUITable = ({ data, columns }: TableProps) => {
  return (
    <TableContainer component={Paper} style={{ padding: 30 }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <Coloumns data={columns} />
        <Body data={data} columns={columns} />
      </Table>
    </TableContainer>
  );
};
