import axios from "axios";
import { API_URL } from "../utils";
import { Admin, Board, Class, MCQS, Question, FAQ, Setting, FillInTheBlank } from "../types";

export const loginAdmin = async (data: Admin) => {
  const payload = {
    method: "post",
    url: API_URL + "admin/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const addBoard = async (data: Board, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "board/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateBoard = async (id: string, data: Board, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "board/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getBoards = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "board?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deleteBoard = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "board/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addClass = async (data: Class, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "class/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateClass = async (id: string, data: Class, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "class/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getClasses = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "class?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deleteClass = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "class/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addSubject = async (data: any, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "subject/add",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: data,
  };
  return await axios(payload);
};
export const updatesubject = async (id: string, data: any, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "subject/update/" + id,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: data,
  };
  return await axios(payload);
};
export const getSubjects = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "subject?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deleteSubject = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "subject/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};
export const getAllSubjects = async () => {
  const payload = {
    method: "get",
    url: API_URL + "subject/all",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};

export const getClassSubjects = async (classId: string) => {
  const payload = {
    method: "post",
    url: API_URL + "subject/class",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ classId }),
  };
  return await axios(payload);
};
export const getLimitedClassSubjects = async (
  classId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "subject/class/pagination?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ classId }),
  };
  return await axios(payload);
};

export const getSubjectChapters = async (subjectId: string) => {
  const payload = {
    method: "post",
    url: API_URL + "chapter/subject",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ subjectId }),
  };
  return await axios(payload);
};
export const getPaginatedSubjectChapters = async (
  subjectId: string,
  page: Number,
  limit: Number
) => {
  const payload = {
    method: "post",
    url:
      API_URL + "chapter/subject/pagination?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ subjectId }),
  };
  return await axios(payload);
};
export const getAllClasses = async () => {
  const payload = {
    method: "get",
    url: API_URL + "class/all",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const getAllChapters = async () => {
  const payload = {
    method: "get",
    url: API_URL + "chapter/all",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};

export const addChapter = async (data: any, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "chapter/add",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: data,
  };
  return await axios(payload);
};
export const updateChapter = async (id: string, data: any, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "chapter/update/" + id,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: data,
  };
  return await axios(payload);
};
export const getChapters = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deleteChapter = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "chapter/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addShortQuestion = async (data: Question, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "shortquestion/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateShortQuestion = async (
  id: string,
  data: Question,
  token: string
) => {
  const payload = {
    method: "post",
    url: API_URL + "shortquestion/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getShortQuestions = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "shortquestion?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const getChapterShortQuestions = async (
  chapterId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "shortquestion/chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ chapterId }),
  };
  return await axios(payload);
};
export const deleteShortQuestion = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "shortquestion/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addLongQuestion = async (data: Question, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "longquestion/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateLongQuestion = async (
  id: string,
  data: Question,
  token: string
) => {
  const payload = {
    method: "post",
    url: API_URL + "longquestion/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getLongQuestions = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "longquestion?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const getChapterLongQuestions = async (
  chapterId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "longquestion/chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ chapterId }),
  };
  return await axios(payload);
};
export const deleteLongQuestion = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "longquestion/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addMCQs = async (data: MCQS, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "mcqs/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateMCQS = async (id: string, data: MCQS, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "mcqs/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getMCQS = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "mcqs?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const getChapterMCQS = async (
  chapterId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "mcqs/chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ chapterId }),
  };
  return await axios(payload);
};
export const deleteMCQS = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "mcqs/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addPastPaper = async (data: any, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "past-paper/add",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: data,
  };
  return await axios(payload);
};
export const updatePastPaper = async (id: string, data: any, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "past-paper/update/" + id,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: data,
  };
  return await axios(payload);
};
export const getPapers = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "past-paper?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deletePaper = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "past-paper/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const getPapersData = async () => {
  const subjectsRes = await getAllSubjects();
  const payload = {
    method: "get",
    url: API_URL + "board/all",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const boardsRes = await axios(payload);
  return { boards: boardsRes.data.data, subjects: subjectsRes.data.data };
};
export const getDashboardData = async () => {
  const payload = {
    method: "get",
    url: API_URL + "admin/data",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};

export const addFaq = async (data: FAQ, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "faq/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateFaq = async (id: string, data: FAQ, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "faq/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getFaqs = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "faq/limited?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deleteFaq = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "faq/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const addSetting = async (data: Setting, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "setting/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getSettings = async () => {
  const payload = {
    method: "get",
    url: API_URL + "setting",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};

export const addQuestion = async (data: Question, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "question/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateQuestion = async (id: string, data: Question, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "question/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getQuestions = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "question?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const deleteQuestion = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "question/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};

export const getChapterQuestions = async (
  chapterId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "question/chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ chapterId }),
  };
  return await axios(payload);
};

export const addNumerical = async (data: Question, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "numerical/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateNumerical = async (
  id: string,
  data: Question,
  token: string
) => {
  const payload = {
    method: "post",
    url: API_URL + "numerical/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getNumericals = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "numerical?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const getChapterNumericals = async (
  chapterId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "numerical/chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ chapterId }),
  };
  return await axios(payload);
};
export const deleteNumerical = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "numerical/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};


export const addFillintheblank = async (data: FillInTheBlank, token: string) => {
  const payload = {
    method: "post",
    url: API_URL + "fillintheblank/add",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const updateFillintheblank = async (
  id: string,
  data: FillInTheBlank,
  token: string
) => {
  const payload = {
    method: "post",
    url: API_URL + "fillintheblank/update/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: JSON.stringify(data),
  };
  return await axios(payload);
};
export const getFillintheblanks = async (page: number, limit: number) => {
  const payload = {
    method: "get",
    url: API_URL + "fillintheblank?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(payload);
};
export const getChapterFillintheblanks = async (
  chapterId: string,
  page: number,
  limit: number
) => {
  const payload = {
    method: "post",
    url: API_URL + "fillintheblank/chapter?page=" + page + "&limit=" + limit,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ chapterId }),
  };
  return await axios(payload);
};
export const deleteFillintheblank = async (id: string, token: string) => {
  const payload = {
    method: "delete",
    url: API_URL + "fillintheblank/delete/" + id,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return await axios(payload);
};