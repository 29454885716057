import { useState } from "react";
import Input from "../../Components/common/input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Chapter, Store } from "../../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { addChapter, updateChapter } from "../../services";
import useStore from "../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { LANGUAGES } from "../../utils";
import { Select } from "../../Components";

interface ChapterFormProps {
  setOpen: (data: boolean) => void;
  chapterData: Chapter | null;
  classId: any;
  subjectId: any;
}
export const ChapterForm = ({
  setOpen,
  chapterData,
  classId,
  subjectId,
}: ChapterFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<any>({
    _id: chapterData ? chapterData._id : "",
    name: chapterData ? chapterData.name : "",
    language: chapterData ? chapterData.language : LANGUAGES[0].name,
    chapterIndex: chapterData ? chapterData.chapterIndex : 0,
  });

  const validations = yup.object().shape({
    name: yup.string().required(),
    language: yup.string().required(),
    chapterIndex: yup.number().required(),
  });

  const changeHandler = (key: string, value: string) => {
    setState((p: any) => ({
      ...p,
      [key]: value,
    }));
  };

  const clearForm = () => {
    setState({
      _id: "",
      name: "",
      language: "",
      chapterIndex: 0,
    });
  };

  const handleSubmit = async (values: Chapter) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("chapterIndex", values.chapterIndex.toString());
      formData.append("subjectId", subjectId);
      formData.append("classId", classId);
      formData.append("language", values.language);
      if (state._id?.length) {
        await updateChapter(state._id, formData, admin ? admin : "");
        toast.success("Updated sucessfully");
      } else {
        await addChapter(formData, admin ? admin : "");
        toast.success("Added sucessfully");
      }
      clearForm();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <Typography variant="h3">Chapter Form</Typography>
              </Box>
              <Input
                name="name"
                label="Name *"
                value={state.name}
                changeHandler={changeHandler}
              />
              <Input
                name="chapterIndex"
                label="Chapter Number *"
                type="number"
                value={state.chapterIndex}
                changeHandler={changeHandler}
              />
              <Select
                name="language"
                label="Language"
                value={state.language}
                searchKey="name"
                placeholder="Select Language"
                searchValue="name"
                data={LANGUAGES}
                changeHandler={changeHandler}
              />

              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
