import { useState } from "react";
import Input from "../../Components/common/input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Subject, Store } from "../../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { addSubject, updatesubject } from "../../services";
import useStore from "../../store";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { LANGUAGES } from "../../utils";
import { Select } from "../../Components";

interface SubjectFormProps {
  setOpen: (data: boolean) => void;
  subjectData: Subject | null;
  classId: any;
}
export const SubjectForm = ({
  setOpen,
  subjectData,
  classId,
}: SubjectFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<any>({
    _id: subjectData ? subjectData._id : "",
    name: subjectData ? subjectData.name : "",
    tagLine: subjectData ? subjectData.tagLine : "",
    language: subjectData ? subjectData.language : LANGUAGES[0].name,
    icon: subjectData && subjectData.icon ? subjectData.icon : "",
  });

  const validations = yup.object().shape({
    name: yup.string().required(),
    language: yup.string().required(),
    tagLine: yup.string().required(),
  });

  const changeHandler = (key: string, value: string) => {
    setState((p: any) => ({
      ...p,
      [key]: value,
    }));
  };

  const clearForm = () => {
    setState({
      _id: "",
      name: "",
      tagLine: "",
      icon: "",
      language: LANGUAGES[0].name,
    });
  };

  const handleSubmit = async (values: Subject) => {
    if (!state._id?.length && !image) {
      toast.error("Image is required!");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("tagLine", values.tagLine);
      formData.append("language", values.language);
      formData.append("classId", classId);
      if (image) {
        formData.append("file", image);
      }
      if (state._id?.length) {
        await updatesubject(state._id, formData, admin ? admin : "");
        toast.success("Updated sucessfully");
      } else {
        await addSubject(formData, admin ? admin : "");
        toast.success("Added sucessfully");
      }
      clearForm();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <Typography variant="h3">Subject Form</Typography>
              </Box>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "300%",
                  maxHeight: { xs: 233, md: 200 },
                  maxWidth: { xs: 350, md: 350 },
                  mb: 1,
                  borderRadius: 1,
                }}
                alt="SubjectImage"
                src={
                  image
                    ? URL.createObjectURL(image)
                    : state.icon.length
                    ? state.icon
                    : "/assets/placeholder.jpg"
                }
              />
              <Button variant="outlined" component="label">
                Upload File *
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    e.target.files && setImage(e.target.files[0])
                  }
                />
              </Button>
              <Input
                name="name"
                label="Name *"
                value={state.name}
                changeHandler={changeHandler}
              />
              <Input
                name="tagLine"
                label="TagLine *"
                value={state.tagLine}
                changeHandler={changeHandler}
              />
              <Select
                name="language"
                label="Language"
                value={state.language}
                searchKey="name"
                searchValue="name"
                data={LANGUAGES}
                changeHandler={changeHandler}
              />
              <Box mt={3}>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
