import { useEffect, useState } from "react";
import RichEditor from "../../Components/common/richTextEditor";
import { Setting, Store } from "../../types";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { getSettings, addSetting } from "../../services";
import useStore from "../../store";
import { Loader } from "../../Components";

export const AppSetting = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { admin } = useStore((store: Store) => store);
  const [state, setState] = useState<Setting>({
    termsAndConditions: "",
    privacyPolicy: "",
  });

  useEffect(() => {
    getSettings()
      .then((res) => {
        const data = res.data.data;
        console.log(data);

        setState({
          termsAndConditions: data?.termsAndConditions,
          privacyPolicy: data?.privacyPolicy,
        });
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err.message);
      });
  }, []);

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addSetting(state, admin ? admin : "");
      toast.success("Updated successfully");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h3">Setting Form</Typography>
      </Box>

      <Box ml={2} my={1} width="100%">
        <RichEditor
          name="privacyPolicy"
          label="Privacy Policy"
          value={state.privacyPolicy}
          textHandler={changeHandler}
        />
      </Box>
      <Box ml={2} my={1} width="100%">
        <RichEditor
          name="termsAndConditions"
          label="Terms & Conditions"
          value={state.termsAndConditions}
          textHandler={changeHandler}
        />
      </Box>
      <Box mt={3}>
        <Button variant="outlined" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
