import { Box } from "@mui/material";
import Lottie from "react-lottie-player";
import SpinnerJSon from "./loader.json";

export const Loader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Lottie
        loop
        animationData={SpinnerJSon}
        play
        style={{ width: 200, height: 200 }}
      />
    </Box>
  );
};

